import { useNavigate } from "react-router-dom";
import { Parallax } from "react-parallax";
import getStarted from "../assets/images/getStarted.jpeg";
import "../assets/styles/styles.css";

const GetStarted = () => {
  const navigate = useNavigate();
  return (
    <Parallax
      bgImage={getStarted}
      bgImageStyle={{
        width: "100%",
        objectFit: "cover",
        marginTop: "150px",
        backgroundColor: "black",
      }}
      strength={400}
      className="relative bg-black object-cover h-[450px] md:h-[500px] lg:h-[565px] flex flex-col items-center justify-center w-full"
    >
      {/* <div className="bg-black/20 h-full w-full absolute z-[0] top-0"></div> */}
      <h2 className="relative z-1 text-white border-yellow border-[8px] w-fit font-bold text-[35px] sm:text-[40px] md:text-[50px] uppercase border-r-0 px-[10px] my-0">
        Get <span className="text-yellow">Started</span>
      </h2>
      <div className="relative z-1 flex gap-[30px] mt-[40px]">
        <a
          href="tel:(425)-531-2713"
          className="flex justify-center items-center h-[50px] sm:h-[55px] md:h-[60px] bg-yellow w-[120px] sm:w-[150px] md:w-[170px] text-[12px] md:text-[13px] font-bold cursor-pointer transition-all duration-300 hover:bg-white"
        >
          (425) 531-2713
        </a>
        <div
          className="flex justify-center items-center h-[50px] sm:h-[55px] md:h-[60px] bg-yellow w-[120px] sm:w-[150px] md:w-[170px] uppercase font-extrabold cursor-pointer text-[12px] md:text-[13px] tracking-wide transition-all duration-300 hover:bg-white"
          onClick={() => navigate("/contact")}
        >
          Get estimate
        </div>
      </div>
    </Parallax>
  );
};

export default GetStarted;
