import "./App.css";
import Router from "./routes/Router";
import ScrollToTop from "./utils/ScrollToTop";
import "./assets/styles/styles.css";

function App() {
  return (
    <>
      <ScrollToTop />
      <Router />
    </>
  );
}
export default App;
