import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import startway from "../assets/icons/starWay.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center bg-black">
      <div className=" max-w-[1200px] w-full text-white px-[10px] lg:px-[40px] pt-[70px] pb-[15px]">
        <div className="hidden md:flex max-w-[1200px] w-[100%]">
          <div className="max-w-[400px] w-full flex flex-col justify-center ">
            <p className="text-[30px]">Advanced Tint LLC</p>
            <p>Since 2007</p>
            <p className="mt-[5px]">Licenced Bonded Insured</p>
          </div>
          <div className="max-w-[500px] w-[100%] flex flex-col justify-center items-center">
            <div className="flex gap-[30px]">
              <p className="">Mon - Fri</p>
              <p className="">9:00 am - 7:00 pm</p>
            </div>
            <div className="flex gap-[30px]">
              <p className="">Sat - Sun</p>
              <p className="w-[129px]">Closed</p>
            </div>
          </div>
          <div className="max-w-[300px] w-[100%] flex flex-col gap-[10px]">
            <a href="tel:(425)531-2713">(425) 531-2713</a>
            <p className="cursor-pointer" onClick={() => navigate("/contact")}>
              4233 NE Sunset Blvd #7 Renton Wa, 98059
            </p>
          </div>
        </div>
        <div className="hidden md:flex max-w-[1200px] pt-[30px] w-[100%]">
          <div className="max-w-[700px] w-full text-[14px] flex gap-[15px] items-center">
            <p className="uppercase">
              ACCESSIBILITY STATEMENT | PRIVACY POLICY
            </p>
            <p className="">
              © 2023 
              <a href="https://seattlewindowtint.com/">seattlewindowtint.com</a>
            </p>
          </div>
          <div className="max-w-[500px] w-[100%] flex gap-[20px] justify-center items-center">
            <a href="https://www.facebook.com/profile.php?id=100053554226678">
              <FaFacebookF className="text-[23px]" />
            </a>
            <a href="https://instagram.com/advancedtint?igshid=YmMyMTA2M2Y=">
              <FaInstagram className="text-[26px]" />
            </a>
            <a href="https://www.youtube.com/channel/UCfWbkKAyLFEwXr6fuM7iL1w">
              <FaYoutube className="text-[28px]" />
            </a>
          </div>
          {/* <div className="max-w-[300px] w-[100%] flex justify-center">
          <a href="https://starway.agency/">
            <img className="h-[60px]" src={startway} />
            </a>
          </div> */}
        </div>

        {/* Mobile footer */}
        <div className="flex md:hidden flex-col items-center">
          <h2 className="text-[30px] font-extrabold">Advanced Tint LLC</h2>
          <p>Since 2007</p>
          <p className="mt-[2px]">Licenced Bonded Insured</p>
          <div className="max-w-[500px] w-[100%] flex gap-[20px] justify-center items-center mt-[15px]">
            <a href="https://www.facebook.com/profile.php?id=100053554226678">
              <FaFacebookF className="text-[20px]" />
            </a>
            <a href="https://instagram.com/advancedtint?igshid=YmMyMTA2M2Y=">
              <FaInstagram className="text-[22px]" />
            </a>
            <a href="https://www.youtube.com/channel/UCfWbkKAyLFEwXr6fuM7iL1w">
              <FaYoutube className="text-[24px]" />
            </a>
          </div>

          <h2 className="uppercase text-[30px] font-extrabold m-0 mt-[35px]">
            Phone
          </h2>
          <p className="text-[15px]">(425) 531-2713</p>
          <a
            href="tel:(425) 531-2713"
            className="flex justify-center items-center uppercase text-[16px] font-bold bg-yellow text-black h-[50px] w-[160px] mt-[10px]"
          >
            Call now
          </a>
          <h2 className="uppercase text-[30px] font-extrabold m-0 mt-[35px]">
            ADDRESS
          </h2>
          <p className="text-[15px] text-center w-[200px]">
            4233 NE Sunset Blvd #7 Renton Wa, 98059
          </p>
          <a
            href="https://www.google.com/maps/place/Advanced+Window+Tinting,+Xpel+Protection+Film+%26+Car+Clear+Bra/@47.503332,-122.162421,20z/data=!4m6!3m5!1s0x549068635453c809:0x2d92788746e6d34b!8m2!3d47.503197!4d-122.162371!16s%2Fg%2F1tfqqng7?hl=en&entry=ttu"
            className="flex justify-center items-center uppercase bg-yellow font-bold text-[16px] text-black h-[50px] w-[160px] mt-[10px]"
          >
            Get directions
          </a>
          <h2 className="uppercase text-[30px] font-extrabold m-0 mt-[35px]">
            BUSINESS HOURS
          </h2>
          <div className="w-[250px] mx-auto flex text-[15px] justify-center gap-[15px]">
            <div className="flex flex-col items-end">
              <p className="w-fit">Mon - Fri</p>
              <p className="w-fit">Sat - Sun</p>
            </div>
            <div>
              <p className="">9:00 am - 7:00 pm</p>
              <p className="">Closed</p>
            </div>
          </div>
          {/* <a className="my-[25px]" href="https://starway.agency/">
          <img className="h-[50px]" src={startway} />
        </a> */}
          <div className="max-w-[400px] w-full text-[12px] flex flex-col justify-center mt-[15px]">
            <p className="uppercase text-center">
              ACCESSIBILITY STATEMENT | PRIVACY POLICY
            </p>
            <p className="text-center">
              © 2023 
              <a href="https://seattlewindowtint.com/">seattlewindowtint.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
