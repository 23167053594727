import { React, lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loading from "../components/Loading";
import NavbarFooter from "../layouts/NavbarFooter";

const Home = lazy(() => import("../pages/Home"));
const Partners = lazy(() => import("../pages/Partners"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const Contact = lazy(() => import("../pages/Contact"));
const WindowTinding = lazy(() => import("../pages/services/WindowTinding"));
const PaintProtection = lazy(() => import("../pages/services/PaintProtection"));
const RockChipRepair = lazy(() => import("../pages/services/RockChipRepair"));
const CommercialWindowTinding = lazy(() =>
  import("../pages/services/CommercialWindowTinding")
);

function Router() {
  let routes = useRoutes([
    {
      element: (
        <Suspense fallback={<Loading />}>
          <NavbarFooter />
        </Suspense>
      ),
      children: [
        {
          element: (
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          ),
          path: "/",
        },
        {
          element: (
            <Suspense fallback={<Loading />}>
              <Partners />
            </Suspense>
          ),
          path: "/partners",
        },
        {
          element: (
            <Suspense fallback={<Loading />}>
              <AboutUs />
            </Suspense>
          ),
          path: "/about",
        },
        {
          element: (
            <Suspense fallback={<Loading />}>
              <Contact />
            </Suspense>
          ),
          path: "/contact",
        },
        {
          element: (
            <Suspense fallback={<Loading />}>
              <WindowTinding />
            </Suspense>
          ),
          path: "/WINDOW TINTING",
        },
        {
          element: (
            <Suspense fallback={<Loading />}>
              <PaintProtection />
            </Suspense>
          ),
          path: "/PAINT PROTECTION",
        },
        {
          element: (
            <Suspense fallback={<Loading />}>
              <CommercialWindowTinding />
            </Suspense>
          ),
          path: "/COMMERCIAL RESIDENTIAL WINDOW TINT",
        },
        {
          element: (
            <Suspense fallback={<Loading />}>
              <RockChipRepair />
            </Suspense>
          ),
          path: "/ROCK CHIP REPAIR",
        },
      ],
    },
    {
      element: <Loading />,
      path: "/loading",
    },
    {
      element: <Navigate to="/" />,
      path: "*",
    },
  ]);
  return routes;
}

export default Router;
