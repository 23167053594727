import React from "react";
import { BeatLoader } from "react-spinners";
import Logo from "../assets/images/logo.webp";

function PageLoading() {
  return (
    <div className="flex fixed top-0 z-10 items-center justify-center flex-col h-[100vh] w-[100vw] bg-black">
      <img className="h-[165px] mb-[15px]" src={Logo} alt="Turkmen Express" />
      <BeatLoader color={"white"} loading={true} width={"550px"} />
    </div>
  );
}

export default PageLoading;
