import { React, useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import logo from "../assets/images/logo.webp";
import "./Navbar.css";

function Navbar(props) {
  const navigate = useNavigate();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [navBg, setNavBg] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);

  const changeNavBg = () => {
    window.scrollY >= 75 ? setNavBg(true) : setNavBg(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);

  const services = [
    {
      text: "Window Tinting",
      href: "/WINDOW%20TINTING",
    },
    {
      text: "Clear Bra Paint Protection",
      href: "/PAINT%20PROTECTION",
    },
    {
      text: "Commercial & Residential Window Tinting",
      href: "/COMMERCIAL%20RESIDENTIAL%20WINDOW%20TINT",
    },
    {
      text: "Rock Chip Repair",
      href: "/ROCK%20CHIP%20REPAIR",
    },
  ];

  const getPath = () => {
    const path = window.location.pathname;
    const splitted = path.split("/");
    return "/" + splitted[1];
  };

  const navigatefunc = (route) => {
    setHamburgerOpen(!hamburgerOpen);
    navigate(route);
  };

  return (
    <>
      <div
        className={`flex flex-col items-center justify-center fixed overflow-hidden top-0 z-[100] h-[100vh] transition-all duration-500 ${
          hamburgerOpen ? "w-[100vw] bg-black" : "w-0"
        }`}
      >
        <div className={hamburgerOpen ? "block" : "hidden"}>
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 1,
              delay: 0.5,
            }}
            className={`text-white text-[30px] cursor-pointer select-none ${
              getPath() == "/" && "!text-yellow"
            }`}
            onClick={() => navigatefunc("/")}
          >
            Home
          </motion.h2>
          <div className={`text-white text-[30px] `}>
            <motion.h2
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                duration: 1,
                delay: 0.6,
              }}
              className={`flex items-center gap-[5px] ${
                (getPath() == "/WINDOW%20TINTING" ||
                  getPath() == "/PAINT%20PROTECTION" ||
                  getPath() == "/COMMERCIAL%20RESIDENTIAL%20WINDOW%20TINT" ||
                  getPath() == "/ROCK%20CHIP%20REPAIR") &&
                "text-yellow"
              }`}
              onClick={() => setServicesOpen(!servicesOpen)}
            >
              Services
              {/* {servicesOpen ? <FaAngleDown className={``} /> : <FaAngleUp />} */}
              <FaAngleDown
                className={`transition-all duration-300 ${
                  servicesOpen && "rotate-[180deg]"
                }`}
              />
            </motion.h2>
            <div
              className={`ml-[20px] bg-black transition-all duration-500 ${
                servicesOpen ? "h-[295px]" : "h-0"
              }`}
            >
              {services.map((item, index) => (
                <p
                  key={index}
                  className={`text-[25px] mb-[10px] w-[200px] transition-all duration-700 ${
                    servicesOpen ? "opacity-[100%]" : "hidden"
                  } ${getPath() == item.href && "text-yellow"}`}
                  onClick={() => navigatefunc(item.href)}
                >
                  {item.text}
                </p>
              ))}
            </div>
          </div>
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 1,
              delay: 0.7,
            }}
            className={`text-white text-[30px] cursor-pointer ${
              getPath() == "/about" && "text-yellow"
            }`}
            onClick={() => navigatefunc("/about")}
          >
            About Us
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 1,
              delay: 0.8,
            }}
            className={`text-white text-[30px] cursor-pointer ${
              getPath() == "/partners" && "text-yellow"
            }`}
            onClick={() => navigatefunc("/partners")}
          >
            Gallery
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 1,
              delay: 0.9,
            }}
            className={`text-white text-[30px] cursor-pointer ${
              getPath() == "/contact" && "text-yellow"
            }`}
            onClick={() => navigatefunc("/contact")}
          >
            Contact
          </motion.h2>
        </div>
      </div>
      <header
        className={`fixed z-[100] !top-[0px] h-[80px] flex justify-between items-center md:hidden w-[100vw] transition-all duration-500 pl-[5px] pr-[15px] py-[10px] ${
          navBg ? "bg-black" : "bg-transparent"
        } `}
      >
        <div
          className={`flex flex-col gap-[6px] items-center justify-center w-[40px] md:w-[43px] h-[40px] sm:h-[40px] cursor-pointer lg:hidden`}
          onClick={() => setHamburgerOpen(!hamburgerOpen)}
        >
          <div
            className={`relative w-[20px] sm:w-[18px] h-[2px] top-[0px] rotate-[0deg] bg-yellow transition-all duration-500 ${
              hamburgerOpen && "firstLineToggled"
            }`}
          ></div>
          <div
            className={`relative w-[20px] sm:w-[18px] h-[2px] bottom-0 bg-yellow transition-all duration-500 ${
              hamburgerOpen && "secondLineToggled"
            }`}
          ></div>
        </div>
        <img
          className="h-[55px]"
          src={logo}
          onClick={() => navigate("/")}
          alt="Advanced tint"
        />
        <div
          className="flex items-center justify-center bg-yellow text-black w-fit h-[37px] px-[10px] md:px-[25px] lg:px-[32px] cursor-pointer hover:bg-white"
          onClick={() => navigate("/contact")}
        >
          Get estimate
        </div>
      </header>
      <header
        className={`hidden md:flex fixed z-[100] top-[0px] items-center md:justify-between pr-[20px] w-full transition-all h-fit ${
          navBg ? "bg-black" : "bg-transparent"
        }`}
      >
        <div className="w-[18%] lg:w-[20%]">
          <div
            className={` flex items-center justify-center transition-all cursor-pointer md:w-[95%] lg:w-[150px] h-[100px] ${
              navBg
                ? "md:w-[95%] lg:w-[150px] h-[100px]"
                : "md:w-[95%] lg:w-[180px] h-[131px]"
            }`}
            onClick={() => navigate("/")}
          >
            <img
              className={`w-[100px] h-[100px] transition-all  ${
                navBg ? "w-[75px] h-[75px]" : "w-[85px] h-[85px]"
              }`}
              src={logo}
            />
          </div>
        </div>

        <div className="flex md:gap-[10px] lg:gap-[20px] text-[17px] uppercase text-white ">
          <p
            className={`cursor-pointer hover:text-yellow ${
              getPath() == "/" && "text-yellow"
            }`}
            onClick={() => navigate("/")}
          >
            Home
          </p>
          <div className="group cursor-pointer relative">
            <p
              className={`flex justify-center items-center gap-[5px] hover:text-yellow pb-[5px] ${
                (getPath() == "/WINDOW%20TINTING" ||
                  getPath() == "/PAINT%20PROTECTION" ||
                  getPath() == "/COMMERCIAL%20RESIDENTIAL%20WINDOW%20TINT" ||
                  getPath() == "/ROCK%20CHIP%20REPAIR") &&
                "text-yellow"
              }`}
            >
              Services <FaAngleDown className="" />
            </p>
            <div className="hidden group-hover:flex flex-col absolute w-max bg-black text-[14px] py-[15px] pl-[15px] pr-[30px] gap-[20px] transition-all duration-300">
              {services.map((item, index) => (
                <p
                  className={`hover:text-yellow ${
                    getPath() == item.href && "text-yellow"
                  }`}
                  key={index}
                  onClick={() => navigate(item.href)}
                >
                  {item.text}
                </p>
              ))}
            </div>
          </div>
          <p
            className={`cursor-pointer hover:text-yellow ${
              getPath() == "/about" && "text-yellow"
            }`}
            onClick={() => navigate("/about")}
          >
            About us
          </p>
          <p
            className={`cursor-pointer hover:text-yellow ${
              getPath() == "/partners" && "text-yellow"
            }`}
            onClick={() => navigate("/partners")}
          >
            Gallery
          </p>
          <p
            className={`cursor-pointer hover:text-yellow ${
              getPath() == "/contact" && "text-yellow"
            }`}
            onClick={() => navigate("/contact")}
          >
            Contact
          </p>
        </div>

        <div className="flex flex-col items-center uppercase text-[12px] gap-[10px] lg:ml-auto md:w-[20%] lg:w-[25%]">
          <div
            className="flex items-center justify-center bg-yellow text-black w-fit h-[37px] md:px-[25px] lg:px-[32px] cursor-pointer hover:bg-white"
            onClick={() => navigate("/contact")}
          >
            Get estimate
          </div>
          <a
            href="tel:(425) 531-2713"
            className="flex justify-center items-center bg-transparent cursor-pointer text-yellow w-fit h-[37px] md:px-[22px] lg:px-[30px] border-[yellow] border-[2px] hover:text-white hover:border-white"
          >
            (425) 531-2713
          </a>
        </div>
      </header>
    </>
  );
}

export default Navbar;
