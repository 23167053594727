import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Navbar from "../components/Navbar";

const NavbarFooter = () => {
  return (
    <div className="max-w-[100vw]">
      <Navbar />
      <Outlet />
      <GetStarted />
      <Footer />
    </div>
  );
};

export default NavbarFooter;
